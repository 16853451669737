.rotate {
  animation: rotation 60s infinite linear;
}

.fast_rotate {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.Clock {
  position: relative;
  width: 320px;
  height: 320px;
}

.ClockHolder {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 320px;
}

.ClockHolderMini {
  padding-bottom: 76px;
}
