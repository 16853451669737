.PhoneStock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100vw;
  height:100vh;
  box-sizing: border-box;
  max-height: -webkit-fill-available;
}

.Window {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 450px;
  padding: 5px;
  background: #D8D8D8;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.50);
  border-radius: 25px;
  z-index: 9999;
}

.Screen {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 210px;
  height: 440px;
  border-radius: 15px;
  background: #4A4A4A;
}

.TopBar {
  width: 150px;
  height: 15px;
  background: #D8D8D8;
  border-radius: 0px 0px 5px 5px;
  margin-top: -5px;
}

.Content {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 200px;
}

.Ticker {
  position: absolute;
  font-family: ClearSans;
  font-size: 24px;
  color: #D8D8D8;
  letter-spacing: 2px;
  text-align: center;
  top: 10px;
  left: 5px;
}

.TickerText {
  color: #BD93F9;
}

.Performance {
  position: absolute;
  background: rgba(0,0,0,0.30);
  border-radius: 10px;
  bottom: 5px;
  right: 0px;
  font-family: ClearSans;
  font-size: 16px;
  color: #E1E1E1;
  letter-spacing: 1.33px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.GoodExpectedPerformance {
  position: absolute;
  background: #46D3A8;
  border-radius: 5px;
  top: 11px;
  right: 5px;
  font-family: ClearSans;
  font-size: 16px;
  color: #E1E1E1;
  letter-spacing: 1.33px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-shadow: 0 0 2px #1CFFBB;
}

.BadExpectedPerformance {
  position: absolute;
  background: #F44940;
  border-radius: 5px;
  top: 11px;
  right: 5px;
  font-family: ClearSans;
  font-size: 16px;
  color: #E1E1E1;
  letter-spacing: 1.33px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-shadow: 0 0 2px #FF271C;
}