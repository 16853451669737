.AppStock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100vw;
  height:100vh;
}

.Spacer {
  width: 7px;
}

.VSpacer {
  height: 65px;
}

.TopBar {
  height: 41px;
  align-items: center;
  display: flex;
}

.Minimize {
  background: #ECBB38;
  width: 15px;
  height: 15px;
  border-radius: 8px;
}

.Maximize {
  background: #46D3A8;
  width: 15px;
  height: 15px;
  border-radius: 8px;
}

.Close {
  background: #F54840;
  width: 15px;
  height: 15px;
  border-radius: 8px;
}

.Content {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  background: #4A4A4A;
  border-radius: 8px;
  padding: 2px;
}

.Window {
  display: flex;
  flex-direction: column;
  width: 650px;
  height: 402px;
  padding: 5px;
  background: #D8D8D8;
  border-radius: 13px;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.50);
  border-radius: 5px;
}

.Ticker {
  position: absolute;
  font-family: ClearSans;
  font-size: 24px;
  color: #D8D8D8;
  letter-spacing: 2px;
  text-align: center;
  top: 10px;
  left: 15px;
}

.GoodExpectedPerformance {
  position: absolute;
  background: #46D3A8;
  border-radius: 5px;
  top: 65px;
  right: 15px;
  font-family: ClearSans;
  font-size: 16px;
  color: #E1E1E1;
  letter-spacing: 1.33px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  text-shadow: 0 0 2px #1CFFBB;
}

.BadExpectedPerformance {
  position: absolute;
  background: #F44940;
  border-radius: 5px;
  top: 65px;
  right: 15px;
  font-family: ClearSans;
  font-size: 16px;
  color: #E1E1E1;
  letter-spacing: 1.33px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  text-shadow: 0 0 2px #FF271C;
}

.Performance {
  position: absolute;
  background: rgba(0,0,0,0.30);
  border-radius: 5px;
  top: 10px;
  right: 15px;
  font-family: ClearSans;
  font-size: 16px;
  color: #E1E1E1;
  letter-spacing: 1.33px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.TickerText {
  color: #BD93F9;
}