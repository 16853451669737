.ManMachine {
  font-family: ClearSans-Medium;
  font-size: 24px;
  color: #BDBDBD;
  letter-spacing: 2px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ManMachineStrikeHighlight {
  color: #A670F9;
  text-decoration: line-through;
  position: absolute;
  left: 0px;
  top: 0px;
  animation: withhighlight 15s infinite;
}


.ManMachineHighlight {
  color: #A670F9;
  position: absolute;
  left: 0px;
  top: 0px;
  animation: vshighlight 15s infinite;
}

.ManMachineContainer {
  position: relative;
  animation: machmov 15s infinite;
  height: 30px;
}

.ManMachineWords {
  padding-left: 10px;
  padding-right: 10px;
}

@keyframes machmov {
  0%   {width: 27px;}
  20%  {width: 27px;}
  25%  {width: 50px;}
  45%  {width: 50px;}
  50%  {width: 27px;}
  70%  {width: 27px;}
  75%  {width: 50px;}
  95%  {width: 50px;}
}


@keyframes withhighlight {
  0%   {opacity: 1;}
  20%  {opacity: 1;}
  25%  {opacity: 0;}
  45%  {opacity: 0;}
  50%  {opacity: 1;}
  70%  {opacity: 1;}
  75%  {opacity: 0;}
  95%  {opacity: 0;}
}

@keyframes vshighlight {
  0%   {opacity: 0;}
  20%  {opacity: 0;}
  25%  {opacity: 1;}
  45%  {opacity: 1;}
  50%  {opacity: 0;}
  70%  {opacity: 0;}
  75%  {opacity: 1;}
  95%  {opacity: 1;}
}
