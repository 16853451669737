.Topics {
  padding: 30px;
  padding-top: 60px;
}

.TopicSquare {
  width: 100px;
  height: 100px;
  padding: 25px;
  background: #FFFFFF;
  border: 1px solid #E6E7EB;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ClearSans-Medium;
  font-size: 16px;
  color: #A3A3A8;
  letter-spacing: 1.33px;
  text-align: center;
  cursor: pointer;
}

.TopicSquare:hover {
  background: #E6E7EB;
}