.Switch {
  display: flex;
  flex-direction: row;
}

.SwitchCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 10px;
}

.SwitchButton {
  width: 30px;
  height: 30px;
  background: #D6D6D8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.SwitchButtonWhite {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.SwitchButtonWhite:hover {
  border-radius: 3px;
  background: #F2F2F2;
}

.SwitchSpacer {
  width: 5px;
  height: 30px;
}