.BeliefText {
  font-family: ClearSans-Medium;
  font-size: 24px;
  color: #E8E8E8;
  letter-spacing: 2px;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.BeliefTextDont {
  position: absolute;
  left: 0px;
  color: #A670F9;
  animation: donthighlight 15s infinite;
}

.BeliefTextDo {
  position: absolute;
  left: 0px;
  color: #A670F9;
  animation: dohighlight 15s infinite;
}

.BeliefTextContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  animation: machmov 15s infinite;
}

.BeliefTextSpacer {
  padding-left: 10px;
  padding-right: 10px;
}

@keyframes machmov {
  0%   {width: 75px;}
  20%  {width: 75px;}
  25%  {width: 35px;}
  45%  {width: 35px;}
  50%  {width: 75px;}
  70%  {width: 75px;}
  75%  {width: 35px;}
  95%  {width: 35px;}
}


@keyframes donthighlight {
  0%   {opacity: 1;}
  20%  {opacity: 1;}
  25%  {opacity: 0;}
  45%  {opacity: 0;}
  50%  {opacity: 1;}
  70%  {opacity: 1;}
  75%  {opacity: 0;}
  95%  {opacity: 0;}
}

@keyframes dohighlight {
  0%   {opacity: 0;}
  20%  {opacity: 0;}
  25%  {opacity: 1;}
  45%  {opacity: 1;}
  50%  {opacity: 0;}
  70%  {opacity: 0;}
  75%  {opacity: 1;}
  95%  {opacity: 1;}
}
