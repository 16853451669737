.Banner {
  background: #253237;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  position: absolute;
  top: 0px;
  left: 0px;
}

.BannerCont {
  background: #253237;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  position: relative;
}

.BannerTitle {
  font-family: ClearSans-Bold;
  font-size: 24px;
  color: #E1E1E1;
  letter-spacing: 2px;
  text-align: center;
  text-shadow: 0 0 6px rgba(0,0,0,0.50);
}

.BannerTitleHighlight {
  color: #F0BE00;
}