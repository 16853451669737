.FooterContainer {
  display: flex;
  flex-direction: row;
  font-family: ClearSans-Medium;
  font-size: 14px;
  color: #D4D4D4;
  letter-spacing: 2px;
  text-align: center;
}

.FooterContainerMobile {
  display: flex;
  flex-direction: column;
  font-family: ClearSans-Medium;
  font-size: 14px;
  color: #D4D4D4;
  letter-spacing: 2px;
  text-align: center;
}

.FooterElement {
  cursor: pointer;
}

.FooterElement:hover {
  color: #B5B5B5;
}

.FooterTextSpacer {
  padding-left: 20px;
  padding-right: 20px;
}

.FooterTextSpacerMobile {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.FooterCopyRight {
  display: flex;
  font-family: ClearSans-Medium;
  font-size: 14px;
  color: #D4D4D4;
  letter-spacing: 2px;
  text-align: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}