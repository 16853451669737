.EmailSignup {
  display: flex;
  flex-direction: row;
}

.EmailSignupMobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding-left: 35px;
  padding-right: 35px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.EmailText {
  background: none;
  border: none;
  border: 2px solid #D8D8D8;
  background: rgba(0, 0, 0, 0.0);
  font-family: ClearSans-Medium;
  font-size: 16px;
  color: #D8D8D8;
  letter-spacing: 2px;
  text-align: left;
  padding-top: 7px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.50);
  border-radius: 5px;
  width: 300px;
}

.EmailTextMobile {
  -webkit-appearance: none;
  box-shadow: inset 0px 0px 0px 0px red;
  background: none;
  border: none;
  border: 2px solid #D8D8D8;
  background: rgba(0, 0, 0, 0.0);
  font-family: ClearSans-Medium;
  font-size: 16px;
  color: #D8D8D8;
  letter-spacing: 2px;
  text-align: left;
  padding-top: 7px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.EmailTextDark {
  background: none;
  border: none;
  border: 2px solid #D8D8D8;
  background: rgba(0, 0, 0, 0.0);
  font-family: ClearSans-Medium;
  font-size: 16px;
  color: #D8D8D8;
  letter-spacing: 2px;
  text-align: left;
  padding-top: 7px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  width: 300px;
}

.EmailButton:hover {
  background: #B281F9;
}

.EmailButton:active {
  background: #A369F9;
}

.EmailButton {
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #BC93F9;
  border-radius: 5px;
  padding-top: 7px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: ClearSans-Bold;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 2px;
  text-align: center;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.50);
  text-shadow: 0 0 2px rgba(144,69,255,1.0);
}

.EmailButtonMobile {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #BC93F9;
  border-radius: 5px;
  padding-top: 9px;
  padding-bottom: 11px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: ClearSans-Bold;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 2px;
  text-align: center;
  text-shadow: 0 0 2px rgba(144,69,255,1.0);
  width: 100%;
  box-sizing: border-box;
  margin-top: 15px;
}

.EmailButtonDark:hover {
  background: #B281F9;
}

.EmailButtonDark:active {
  background: #A369F9;
}

.EmailButtonDark {
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #BC93F9;
  border-radius: 5px;
  padding-top: 7px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: ClearSans-Bold;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 2px;
  text-align: center;
  text-shadow: 0 0 2px rgba(144,69,255,1.0);
}

textarea:focus, input:focus{
    outline: none;
}