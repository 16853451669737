.Bar {
  opacity: 0.65;
  background: #FFFFFF;
  border: 1px solid #BDBEC2;
  border-radius: 16px;
  height: 21px;
}

.BarElement {
  background: #A670F9;
  border-radius: 16px;
  height: 21px;
}

.BarLabel {
  text-align: left;
  font-family: ClearSans-Medium;
  font-size: 16px;
  color: #D1D2D5;
  letter-spacing: 1.33px;
  padding-top: 10px;
  padding-bottom: 25px;
}

.BarSector {
  color: #C0C0C2;
}