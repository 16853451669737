.Asking {
  background: #FFFFFF;
  border: 1px solid #E5E5EA;
  border-radius: 16px;
  max-width: 483px;
  height: 98px;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

}

.AskingCont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.AskingText {
  font-family: ClearSans-Medium;
  font-size: 15px;
  color: #D1D2D1;
  letter-spacing: 1.33px;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.AskingTextHighlight {
  color: #A670F9;
  padding-left: 5px;
  padding-right: 5px;
}

.AskingTextMobile {
  font-family: ClearSans-Medium;
  font-size: 4vw;
  color: #D1D2D1;
  letter-spacing: 1.33px;
  text-align: center;
}

.AskingTextMobileHighlight {
  color: #A670F9;
  padding-left: 5px;
  padding-right: 5px;
}

.AskingFeedbackContainer {
  padding-top: 20px;
}

.AskingFeedbackColor {
  width:20px;
  height: 20px;
  background: grey;
  border-radius: 10px;
}