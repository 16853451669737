.TitleCont {
  font-family: ClearSans-Bold;
  font-size: 24px;
  color: #E1E1E1;
  letter-spacing: 2px;
  text-align: center;
  padding-top: 25px;
  cursor: pointer;
}

.TitleHighlight {
  color: #F0BE00;
}

.TitleBlog {
  font-family: ClearSans-Medium;
  font-size: 21px;
  color: #BFBFC8;
  letter-spacing: 2.42px;
  text-align: center;
}