.Machine {
  background: #D1D1D6;
  border-radius: 4px;
  width: 187px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MachineBackPlate {
  background: #C2C2C7;
  border-radius: 16px;
  width: 115px;
  height: 32px;
  display: flex;
}

.MachineVentContainer {
  display: flex;
  flex-direction: column;
  height: 22px;
  padding-top: 5px;
  padding-bottom: 5px;
  flex: 1;
  justify-content: space-around;
  align-items: center;
}

.MachineCircle {
  background: #B0B0B0;
  height: 6px;
  width: 6px;
  border-radius: 3px;
}

.MachineVent {
  background: #A1A1A1;
  border-radius: 2px;
  width: 58px;
  height: 2px;
}

.MachineLights {
  height: 32px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MachineCircular {
  background: #A9A9AD;
  border-radius: 16px;
  width: 32px;
  height: 32px;
}