.GoodRoom {
  width: 476px;
  max-width: 476px;
  width: 95%;
}

.GoodRoomTitle {
  font-family: ClearSans-Medium;
  font-size: 28px;
  color: #CBCBCB;
  letter-spacing: 2.67px;
  text-align: center;
  padding-bottom: 42px;
}

.GoodRoomMobileTitle {
  font-family: ClearSans-Medium;
  font-size: 5.1vw;
  color: #CBCBCB;
  letter-spacing: 2px;
  text-align: center;
  padding-bottom: 42px;
}