.BrainComputer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.BrainComputerMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BrainComputerSpacer {
  padding-top: 73px;
  padding-bottom: 73px;
  padding-left: 73px;
  padding-right: 73px;
}