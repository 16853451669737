.BadRoom {
  max-width: 476px;
  width: 95%;
}

.BadRoomTitle {
  font-family: ClearSans-Medium;
  font-size: 24px;
  color: #CBCBCB;
  letter-spacing: 2px;
  text-align: center;
  padding-bottom: 42px;
}

.BadRoomMobileTitle {
  font-family: ClearSans-Medium;
  font-size: 4.1vw;
  color: #CBCBCB;
  letter-spacing: 2px;
  text-align: center;
  padding-bottom: 42px;
}