.Info {
  height: 4354px;
  width: 100vw;
  margin-top: 100vh;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #253237;
}

.InfoMobile {
  height: 4700px;
  width: 100vw;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #253237;
}

.InfoMobileSpacer {
  height: 100vh;
  width: 100vw;
  max-height: -webkit-fill-available;
}

.HRBlock {
  width: 50px;
  height: 1px;
  background: #D8D8D8;
}

.InfoCheveronCont {
  height:100vh;
  width:100vw;
  z-index: -99;
  background: blue;
}

.InfoCheveron {
  display: flex;
  justify-content: center;
  align-items: center;
  height:5vh;
  width:100vw;
  margin-top: -30px;
  z-index: 999999;
}

.InfoContent {
  flex-grow: 1;
  background: white;
  border-radius: 8px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

.InfoTitle {
  color: #D8D8D8;
  font-family: ClearSans-Medium;
  font-size: 60px;
  color: #E8E8E8;
  letter-spacing: 5px;
  text-align: center;
}

.InfoTitleMobile {
  color: #D8D8D8;
  font-family: ClearSans-Medium;
  font-size: 13vw;
  color: #E8E8E8;
  letter-spacing: 5px;
  text-align: center;
}

.Glasses {
  max-width: 90%;
}

.Spacer {
  height:30px;
  width:30px;
}