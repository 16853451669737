.Articles {
  padding-top: 45px;
}

.ArticleListElement {
  width: 100%;
  opacity: 0.65;
  background: #FFFFFF;
  border: 1px solid #BDBEC2;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
}

.ArticleListElement:hover {
  background: #F2F2F2;
}

.ArticleListTitle {
  font-family: ClearSans-Medium;
  font-size: 20px;
  color: #A9A9AC;
  letter-spacing: 1.33px;
}

.ArticleListSummary {
  font-family: ClearSans;
  font-size: 16px;
  color: #B5B5B5;
  letter-spacing: 1px;
  line-height: 21px;
  padding-top: 5px;
}