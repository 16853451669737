.TopicPageCont {
  display: flex;
  justify-content: center;
}

.TopicPage {
  width: 95%;
  max-width: 880px;
}

.ArticleArrow {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.ArticleArrow:hover {
  opacity: 0.75;
}

.ArticleArrow:active {
  opacity: 0.95;
}