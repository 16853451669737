.Paragraph {
  font-family: ClearSans;
  font-size: 16px;
  color: #787878;
  letter-spacing: 1px;
  text-align: left;
  line-height: 21px;
  text-indent: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}