.SmartAnimation {
  font-family: ClearSans-Medium;
  font-size: 24px;
  color: #BDBDBD;
  letter-spacing: 2px;
  text-align: center;
  display: flex;
}

.SmartAnimationHighlightPeople {
  color: #A670F9;
  position: absolute;
  left: 0px;
  top: 0px;
  animation: people 15s infinite;
}

.SmartAnimationHighlightComputer {
  color: #A670F9;
  position: absolute;
  left: 0px;
  top: 0px;
  animation: computer 15s infinite;
}


.SmartAnimationCont {
  position: relative;
  width: 150px;
  height: 15px;
  animation: mymove 15s infinite;
}

@keyframes mymove {
  0%   {width: 165px;}
  20%  {width: 165px;}
  25%  {width: 103px;}
  45%  {width: 103px;}
  50%  {width: 165px;}
  70%  {width: 165px;}
  75%  {width: 103px;}
  95%  {width: 103px;}
}

@keyframes computer {
  0%   {opacity: 1;}
  20%  {opacity: 1;}
  25%  {opacity: 0;}
  45%  {opacity: 0;}
  50%  {opacity: 1;}
  70%  {opacity: 1;}
  75%  {opacity: 0;}
  95%  {opacity: 0;}
}

@keyframes people {
  0%   {opacity: 0;}
  20%  {opacity: 0;}
  25%  {opacity: 1;}
  45%  {opacity: 1;}
  50%  {opacity: 0;}
  70%  {opacity: 0;}
  75%  {opacity: 1;}
  95%  {opacity: 1;}
}